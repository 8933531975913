@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

body {
  font-family: "Open Sans";
}

.login-bg,
.custom-bg {
  background-image: url("./../images/login-bg.webp") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
  background: none;
}

.header {
  height: 60px !important;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-wrapper {
  width: 100%;
  max-width: 700px;
  /* or adjust as per your requirement */
}

.dropdown-menu.header-dropdown[data-bs-popper] {
  top: 100%;
  left: -150px !important;
  margin-top: var(--bs-dropdown-spacer);
}

.service-list {
  border: 1px solid black !important;
  min-height: 70px !important;
  border-radius: 8px !important;
  background-color: white !important;
  text-decoration: none !important;
  transition: 0.5s !important;
}

/* .service-list:hover, .service-list:focus{box-shadow: 1px 1px 8px white !important; transform: scale(1.1) !important;} */
.service-list h3 {
  font-size: 24px;
  color: black !important;
}

.service-list p {
  font-size: 14px;
  color: black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.sub-header-primary-color {
  background-color: #1d1742 !important;
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
  background-color: #e8f0fe !important;
}

.primary-button {
  background: linear-gradient(to bottom, #19b200 0%, #61fa01 100%) !important;
  height: 40px !important;
}

.login-logo {
  /* width: 140px;
  object-fit: contain; */
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #222222;
  --first-color-light: #848484;
  --white-color: #ffffff;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --blue-color: #0f70cd;
  --lblue-color: #f2fafc;
}

body {
  font-family: "Roboto", sans-serif !important;
  background-color: #e5e5e5 !important;
  /* background-color: #080909 !important; */
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  /* background-color: white; */
  background-color: #1d1742 !important;
  z-index: var(--z-fixed);
  transition: 0.5s;
}

body.login-bg {
  padding: 0 !important;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.content-bg-image {
  height: calc(100vh - 96px);
  /* background-image: url('./../images/admin-panel-bg.jpg'); */
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 230px;
}

.main-title {
  margin-top: 70px;
}

.login-form {
  /* border: 1px solid lightgrey; */
  border-radius: 10px;
  box-shadow: 1px 1px 10px #349fdd;
  background-color: #fff;
}

.primary-bg {
  background-color: #32b8cb !important;
}

.text-color {
  color: #fec52e;
}

.custom-bg-btn {
  /* background-color: #1F1F1F; */
  background-color: cyan;
  /* #504A27; #074795;  #349FDD*/
  border-radius: 10px;
  height: 36px;
  margin-top: 4px;
  padding: 6px 27px !important;
}

.inside-bg {
  /* background           : url("../images/inside-bg.jpg") no-repeat bottom center; */
  background-size: cover;
  background-attachment: fixed;
}

.navbar .navbar-toggler {
  border: none !important;
  padding: 0 !important;
  top: 1rem !important;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--white-color);
  padding: 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
  box-shadow: 0 0px 10px #333333;
}

.body-pd .nav-icon {
  padding-right: 0.5rem;
  transition: 0.5s;
}

.l-navbar.show {
  left: 0;
}

.body-pd .nav_name {
  padding-left: 10px;
}

.inside-bg .l-navbar:hover .nav_name {
  padding-left: 0;
}

header.body-pd .nav_logo {
  display: none;
}

.body-pd .l-navbar:hover .nav_logo {
  padding-top: 8px;
  padding-bottom: 8px;
}

.custom-text-color {
  color: #1f1f1f;
  font-weight: 600;
}

.custom-background-color {
  background-color: #fff;
  box-shadow: 0px 2px 5px #27c87a;
}

.custom-cancel-btn {
  background-color: #eef5ff;
}

.sidemenu-item .active {
  background-color: #fec52e;
  color: white !important;
}

li a:hover {
  background-color: transparent;
  color: #aaa !important;
}

.breadcum-text-color {
  color: #074795 !important;
  font-weight: bold;
}

.sidebar {
  height: calc(100vh - 85px);
  border-right: 1px solid #07479542;
}

.sidebar li {
  list-style-type: none;
  border-bottom: 1px solid #07479542;
}

a.active li {
  background-color: #074795;
  color: #fff;
}

.sidebar li:hover,
.sidebar_settings li:hover {
  background-color: #074795;
  color: #fff;
}

.sidebar li.active {
  background-color: #fec52e;
  color: white;
}

.nav-list li a.active {
  background-color: #fec52e;
  color: white;
}

.nav-link.active {
  /* background-color: #fec52e; */
  color: white;
}

.nav-item:hover {
  background-color: white !important;
  color: black !important;
}

.content-scroll {
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.table_new_ui th {
  border: none;
}

.table_new_ui th {
  background-color: #1d1742 !important;
  color: #fff !important;
}

.breadcrumb>li+li:before {
  content: "" !important;
  text-decoration: none !important;
}

.breadcrumb:hover {
  background-color: transparent !important;
  color: #333333 !important;
}

.breadcrumb {
  padding: 10px 16px;
  font-size: 14px;
  color: #aaa !important;
  letter-spacing: 2px;
  border-radius: 5px !important;
}

.active>.page-link,
.page-link.active {
  background-color: #fec52e !important;
  color: white !important;
}

.disabled {
  background-color: #eef5ff !important;
  color: black !important;
}

.search-position {
  position: relative;
  top: -33px;
  left: 85%;
  padding: 6.5px 5px;
  cursor: pointer;
}

.search-position i.bi {
  font-size: 18px;
}

.active>.page-link,
.page-link.active {
  border-color: #fec52e !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.nav-tabs .nav-link {
  /* color: #fff; */
  border-color: #e98600 !important;
  padding: 8px 18px;
  font-size: 14px;
  /* border-top-right-radius: 50% !important; */
}

.nav-tabs .nav-link:hover {
  background-color: #e98600 !important;
  color: #fff !important;
  /* border-top-right-radius: 50% !important; */
}

.nav-tabs .nav-link.active {
  /* background-color: #074795 !important; */
  background-color: #e98600 !important;
  /* border-top-right-radius: 40px !important; */
  box-shadow: 1px 1px 10px black !important;
  /* padding:10px 50px !important; */
  color: white !important;
}

.nav-tabs .nav-link.active:hover {
  /* border-bottom: 2px solid #074795 !important; */
  color: white !important;
}

.nav-tabs {
  /* border-bottom: var(--bs-nav-tabs-border-width) solid #E98600 !important; */
  border-bottom: 0px !important;
}

.tab-content {
  border: 2px solid #e98600 !important;
  /* background-color:#074795; */
  padding: 20px !important;
  border-radius: 10px !important;
  border-top-left-radius: 0px !important;
  box-shadow: 15px 15x 10px black !important;
}

.modal-box-shadow {
  box-shadow: 1px 1px 10px black;
}

.cursor-pointer {
  cursor: pointer;
}

.custom_link {
  cursor: pointer;
  /* color: #1d1742; */
  color: #0d6efd;
  font-weight: 700;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-2gftvx.Mui-selected {
  background-color: #32b8cb !important;
  color: #fff !important;
}

.modal-show {
  display: block !important;
}

.modal-hide {
  display: none !important;
}

.modal-bg {
  border-radius: 10px !important;
  border: 0px !important;
  box-shadow: 1px 1px 10px black;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.modal-custom {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000ab !important;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex) !important;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg) !important;
}

.modal-backdrop.show {
  opacity: 1 !important;
}

.login_logo {
  height: 20px;
  /* top: -90px; */
  top: -30px;
  position: relative;
}

.header-logo {
  width:135%;
  height: 45px;
}

.login_logo img {
  height: 60px;
}

.custom_spinner {
  position: absolute;
  top: 60px;
  left: 220px;
  height: 100vh;
  width: calc(100vw - 245px) !important;
  /* background: #000000bf; */
  background: #00000075;
  z-index: 25;
}

.custom_spinner .spinner-border {
  position: absolute;
  top: 50%;
  color: white;
}

@media (max-width: 768px) {
  .custom_spinner {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 100vh;
    width: calc(100vw - 0px) !important;
    background: #000000bf;
    z-index: 25;
  }

  .login_logo {
    height: 20px;
    top: -60px;
    position: relative;
  }

  .login_logo img {
    height: 50px;
  }
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  color: #fff;
  background-color: #074795;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  height: 36px;
  padding: 6px 27px !important;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

@media (min-width: 768px) {
  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 0 0 245px;
    background-color: #1d1742 !important;
    /* background-color: #000000 !important; */
  }

  .l-navbar {
    left: 0;
    width: 224px;
    /* background: linear-gradient(to right, rgba(8, 155, 171, 1) 0%, rgba(13, 181, 200, 1) 100%); */
    background-color: white !important;
  }

  .nav_logo,
  .body-pd .l-navbar:hover .nav_logo {
    background-color: #32b8cb;
  }

  .body-pd .nav_logo {
    padding-left: 0.5rem;
    padding-left: 0.8rem;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .l-navbar.show {
    width: calc(var(--nav-width) + 0px);
  }

  .l-navbar:hover {
    width: calc(var(--nav-width) + 156px) !important;
  }

  .l-navbar:hover .nav_logo-icon {
    /* background     : url("../images/logo.png") no-repeat; */
    display: block;
    width: 121px;
    height: 48px;
    background-size: 100%;
    transition: 0.5s;
  }

  .body-pd .l-navbar:hover .nav-icon {
    padding-right: 0;
    transition: 0.5s;
  }

  .body-pd .nav_logo-icon {
    width: 40px;
    height: 40px;
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 15px);
  }
}

@media (max-width: 767.98px) {
  .header {
    height: 65px;
  }

  header.body-pd {
    padding-left: 235px;
  }

  .l-navbar {
    width: 225px;
    left: -235px;
  }

  .l-navbar.show {
    left: 0;
  }

  .body-pd .nav_logo-icon {
    /* background     : url("../images/logo.png") no-repeat; */
    width: 121px;
    height: 48px;
    background-size: 100%;
    margin-left: 8px;
  }

  .body-pd .nav_name {
    padding-left: 0;
  }

  .main-title {
    margin-top: 75px;
    padding-left: 15px;
  }

  .login-logo {
    width: 150px;
  }

  .login-row {
    margin: 0;
  }

  .sidebar {
    top: 6.75rem;
  }

  .nav_logo,
  .nav_link {
    padding-top: 0.55rem;
  }
}

body {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 1.8 !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #a09e9e !important;
  background: #eff7f8 !important;
  /* background-color: #080909 !important; */
}

body {
  color: #333333 !important;
  position: relative;
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

.login-section {
  flex: 1;
  padding: 20px;
  height: 300px;
}

.login-form {
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

button:hover {
  background-color: #32b8cb;
}

.login-bg-section {
  background-color: #f0f7f8;
}

.logo-image {
  width: 84px;
  height: 84px;
  border-radius: 52px;
  object-fit: cover;
}

.user-profile {
  height: 35px;
  width: 35px;
  border: 1px solid #c1c1c1;
  padding: 2px;
  border-radius: 20px;
}

.bell-icon {
  height: 25px !important;
  width: 25px !important;
}

.main-logo {
  width: 170px;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body.login-bg {
  padding: 0 !important;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.breadcrumb {
  font-size: 16px;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--white-color);
  padding: 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
  box-shadow: 0 0px 10px #333333;
}

.navinstitute {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  /* background-color: #000000; */
  /* background: linear-gradient(to right, rgba(8, 155, 171, 1) 0%, rgba(13, 181, 200, 1) 100%); */
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 0rem;
  padding-left: 1rem;
  /* background-color: white; */
  /* background: #32B8CB !important; */
  background-color: #000000;
  border-bottom: solid 1px white;
}

.nav_logo-icon {
  /* background     : url("../images/logo.png") no-repeat; */
  display: block;
  width: 121px;
  height: 48px;
  background-size: 100%;
  transition: 0.5s;
}

.body-pd .nav_logo-icon {
  /* background     : url("../images/logo-small.png") no-repeat; */
  display: block;
  width: 32px;
  height: 32px;
  background-size: 100%;
  transition: 0.5s;
}

.nav_logo-name {
  color: var(--first-color);
  font-weight: 700;
}

.nav_link {
  position: relative;
  color: black;
  transition: 0.3s;
  font-size: 17px;
  white-space: nowrap;
  display: block;
  position: relative;
  padding: 15px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  text-transform: capitalize;
  border-bottom: solid 1px white;
  text-wrap: wrap !important;
  text-align: start !important;
}

.nav_link:hover {
  /* color: black;
  background-color: white; */
  color: white !important;
  background-color: black !important;
}

.nav-icon {
  font-size: 1.25rem;
  width: 18px;
  height: 18px;
  display: inline-block;
}

.body-pd .nav-icon {
  padding-right: 0.5rem;
  transition: 0.5s;
}

.l-navbar.show {
  left: 0;
}

.active {
  color: #32b8cb;
  text-decoration: none;
}

.nav-element.active {
  color: #fff !important;
  background-color: #1d1742 !important;
  /* background: linear-gradient(to bottom, #19b200 0%, #61fa01 100%) !important; */
}

.nav-element:hover {
  color: white !important;
  background-color: #1d1742 !important;
  /* background: linear-gradient(to bottom, #19b200 0%, #61fa01 100%) !important; */
}

.height-100 {
  height: 100vh;
}

a {
  text-decoration: none;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875em;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f24 {
  font-size: 1.5rem;
}

.btn-primary {
  /* background-color: #32B8CB !important; */
  /* background-color: #1d1742 !important; */
  color: black !important;
  background: linear-gradient(to bottom, #19b200 0%, #61fa01 100%) !important;
}

.btn-primary:focus,
.btn-primary:hover {
  background-color: white !important;
  color: black !important;
  /* border: 1px solid #1d1742 !important; */
  background: linear-gradient(to bottom, #19b200 0%, #61fa01 100%) !important;
}

.border-bottom-primary {
  border-bottom: 1px solid #1d1742 !important;
}

.border-top-primary {
  border-top: 1px solid #1d1742 !important;
}

.inside-bg {
  /* background           : url("../images/inside-bg.jpg") no-repeat bottom center; */
  background-size: cover;
  background-attachment: fixed;
}

.navbar .navbar-toggler {
  border: none !important;
  padding: 0 !important;
  top: 1rem !important;
}

.navbar .navbar-toggler:focus {
  box-shadow: none;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  background-color: #222222 !important;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .nav {
  margin: 0 10px;
}

.sidebar .nav-link {
  font-weight: 300;
  color: #f2f2f2;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active,
.sidebar .nav-link.active:focus,
.sidebar .nav-link.active:hover {
  color: #ffffff;
  background-color: #0f70cd;
}

.sidebar .nav-link:focus,
.sidebar .nav-link:hover {
  color: #f07d32;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.navbar {
  /* background-color: #f2f2f2; */
}

.navbar-brand {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  /* background-color: #f2f2f2 !important; */
  text-align: center;
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.15rem 1rem;
}

.navbar .form-control:focus {
  box-shadow: none;
}

.nav_name {
  transition: 0.5s;
}

.body-pd .nav_name {
  padding-left: 10px;
}

.inside-bg .l-navbar:hover .nav_name {
  padding-left: 0;
}

.logo {
  width: 145px;
}

.breadcrumb-hd .breadcrumb-item,
.breadcrumb-hd .breadcrumb-item a {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-decoration: none;
}

.table_new_ui th {
  border: none;
}

.table_new_ui th {
  background-color: #cccccc;
}

.table_new_ui th,
.table_new_ui td {
  font-size: 16px !important;
  color: #333333;
  font-weight: 400;
}

header.body-pd .nav_logo {
  display: none;
}

.body-pd .l-navbar:hover .nav_logo {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .login-block-wraper {
    min-height: calc(100vh - 40px);
  }

  /* .nav_logo{
    border-bottom: 1px !important;
  } */

  .login-block {
    width: 600px;
  }

  .login-fileds {
    border-left: 1px solid #b3b3b3;
    border-top: none;
    margin-top: 0;
  }

  .login-elements .login-elements-icons {
    width: 250px;
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 0 0 245px;
    /* background-color: #000000 !important; */
    background-color: #1d1742 !important;
  }

  .l-navbar {
    left: 0;
    width: 224px;
    background-color: white !important;
  }

  .nav_logo,
  .body-pd .l-navbar:hover .nav_logo {
    /* background-color: #000000; */
    background-color: #1d1742 !important;
  }

  .body-pd .nav_logo {
    padding-left: 0.5rem;
    padding-left: 0.8rem;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .l-navbar.show {
    width: calc(var(--nav-width) + 0px);
  }

  .l-navbar:hover {
    width: calc(var(--nav-width) + 156px) !important;
  }

  .l-navbar:hover .nav_logo-icon {
    display: block;
    width: 121px;
    height: 48px;
    background-size: 100%;
    transition: 0.5s;
  }

  .body-pd .l-navbar:hover .nav-icon {
    padding-right: 0;
    transition: 0.5s;
  }

  .body-pd .nav_logo-icon {
    width: 40px;
    height: 40px;
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 15px);
  }
}

@media (min-width: 992px) {
  .navbar {
    /* background-color: #f2f2f2; */
  }

  .score-card-sticky {
    position: sticky;
    top: 160px;
  }

  .navbar-brand {
    position: relative;
  }

  .navbar-brand:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 10px;
    right: 10px;
  }

  .sidebar,
  .navbar-brand {
    width: 250px;
  }

  .sidebar {
    padding: 70px 0 0;
  }

  .content-waper {
    padding-left: 265px;
    padding-top: 0;
  }

  .global-search {
    width: 450px;
  }

  .signup-quotblock {
    border-right: 1px solid #cccccc;
  }

  .changepass-row {
    min-height: calc(100vh - 220px);
  }

  .deatils-block .list li {
    margin-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .login-block.lg {
    width: 1140px;
    margin-top: 100px;
  }

  .signup-block {
    margin-top: 100px;
  }
}

@keyframes animate-bar {
  0% {
    width: 0;
  }
}

.cursor_pointer {
  cursor: pointer;
}

.content-bg-color {
  background: white;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  border: none;
  -webkit-box-shadow: 5px 5px 5px 5px rgba(12, 10, 10, 0.1);
  /* box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.1); */
  box-shadow: 5px 5px 5px 4px rgba(6, 5, 5, 0.1) !important;
  padding: 15px 30px !important;
}

.bg-flat-color {
  border-radius: 19px;
  box-shadow: 0 1px 8px -1px #000 !important;
}

@media (max-width: 767.99px) {
  .main-logo {
    width: 120px !important;
  }

  .content-bg-image {
    padding-left: 0px !important;
  }
}

.nav_list {
  /* background: white; */
  /* background-color: #000000; */
  /* color:white !important; */
  color: black !important;
}

.header-bottom {
  border-bottom: solid 1px #cccccc;
}

.disabled-link {
  color: rgb(172, 167, 167) !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  background-color: transparent !important;
  content: "" !important
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item a:hover {
  background-color: transparent !important;
  color: #333333 !important;
}

.breadcrumb-item a.active:hover {
  background-color: transparent !important;
  color: #0753a2 !important;
}

.custom-link {
  /* color: #089bab !important; */
  color: #1d1742 !important;
}

.service-list {
  border: 1px solid #1d1742 !important;
  min-height: 130px !important;
  border-radius: 8px !important;
  background-color: white !important;
  text-decoration: none !important;
  transition: 0.5s !important;
}

.service-list:hover,
.service-list:focus {
  box-shadow: 1px 1px 8px white !important;
  transform: scale(1.1) !important;
}

.service-list h3 {
  font-size: 24px;
  color: black !important;
}

.service-list p {
  font-size: 14px;
  color: black !important;
}

.bg-gradient-one {
  background: #e98600 !important;
}

.bg-aqua {
  background-color: #d33105 !important;
}

.bg-sky {
  background-color: #32b8cb !important;
}

.navy {
  background: #286090 !important;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-2gftvx.Mui-selected {
  background-color: #1d1742 !important;
  color: #fff !important;
}

.MuiPagination-ul li {
  margin: 0px 3px;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  border-radius: 50px !important;
  min-width: 32px !important;
  height: 32px !important;
}

.upload-images {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.btn-outline-primary {
  border: 1px solid #1d1742 !important;
  border-radius: 4px;
  color: black !important;
}

.btn-outline-primary:hover {
  /* background-color: #32B8CB !important; */
  background: linear-gradient(to bottom, #19b200cf 0%, #61fa01 100%) !important;
  color: black !important;
}

.nav-icon {
  height: 19px;
  width: 19px;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #1d1742 !important;
}

.animation-bg-image {
  /* background-image: url('./../images/AutoGleam-Logo.png'); */
  background-size: cover;
  /* Ensures the image covers the entire element */
  animation: slide 10s infinite alternate;
  /* Define the animation */
}

@keyframes slide {
  0% {
    background-position: 0% 0%;
    /* Start position */
  }

  100% {
    background-position: 100% 0%;
    /* End position */
  }
}

.height_50 {
  height: 50px !important;
}

.height_30 {
  height: 37px !important;
}

.container_checkbox {
  height: 15px !important;
  width: 15px !important;
}

.pie-charts-one {
  height: 220px !important;
  width: 220px !important;
}

.pie-charts {
  height: 230px !important;
  width: 230px !important;
}

.payment-partner-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.games-content-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.black-bg {
  background-color: #040404 !important;
}

.logo-style {
  max-width: 90% !important;
  height: 44px;
}

.box-shadow {
  box-shadow: 1px 1px 2px black, 0 0 3px black, 0 0 5px black;
}

.btn-bg {
  background: linear-gradient(to bottom, #19b200 0%, #61fa01 100%) !important;
}

.nav-element .text-break {
  text-wrap: wrap !important;
  text-align: start !important;
}

.alignItems {
  align-items: center !important;
}

.btn-border {
  border: 1px solid black !important;
}

.bg_blu {
  background-color: #1d1742;
  height: 100vh;
}

.blz_logo {
  width: 18%;
}

.txt_green {
  color: #93d500;
}

.white-label-text {
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  text-transform: capitalize;
  margin-right: 0px;
  margin-bottom: 40px;
}

.tournaments-text {
  font-size: 76px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  text-transform: capitalize;
  margin-top: 23px;
  margin-right: 0px;
  color: #fcfcfc;
}

.content-kingdom-text {
  margin-top: 60px;
}

.brd_btn {
  border: 2px solid #93d500;
}

.wp-block-buttons {
  display: inline-block;
  margin: 0;
}

.wp-block-button__link {
  border-radius: 0px;
  padding-top: 18px;
  padding-right: 40px;
  padding-bottom: 18px;
  padding-left: 40px;
}

.white_txt {
  color: #fcfcfc;
}

.blz_block {
  box-sizing: border-box;
  /* padding: 25px; */
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.game_banner {
  position: relative;
  right: 2px;
  top: -154px;
}

.game_banner img {
  width: 95%;
  height: 95%;
}

.wp-block-group {
  border-radius: 0px;
  background-color: #14112b;
  padding-top: 50px;
  padding-right: 30px;
  padding-bottom: 50px;
  padding-left: 30px;
  /* width: 80%; */
}

figure {
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.is-style-customborderhover {
  border: 2px solid #14112b;
}

.is-style-customborderhover:hover {
  border: 2px solid #93d500;
}

.wp-block-image {
  width: 18%;
  height: 18%;
}

.wp-block-column {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.wp-block-heading {
  margin-top: 30px;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}

.has-text-color {
  color: #d0d0d0;
}

.premium_brd {
  border: 2px solid #93d500;
}

.game_platform_block {
  background: #08081e;
  padding: 10px 0px;
}

.is-style-lineseparator:before {
  content: "";
  width: 32px;
  margin-right: 20px;
  border-top-width: 3px;
  border-top-color: #93d500;
  border-top-style: solid;
  display: inline-block;
}

.is-style-lineseparator {
  display: flex;
  align-items: center;
  gap: 10px;
}

.games-platform-heading-text {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
}

.core_platform_img {
  width: 85%;
}

.sign_btn {
  /* background: #1db7f5; */
  background: linear-gradient(to bottom, #0780b0 0%, #1db7f5 100%) !important;
  height: 38px;
  border: none;
  font-size: 15px;
}

.sign_btn:hover {
  color: #000 !important;
}

.is-style-customborderbottomhover:hover {
  border-bottom: 5px solid #93d500;
}

.is-style-customborderbottomhover {
  border-bottom: 5px solid #fff;
}

.has-zeever-secondary-background-color {
  background-color: #93d500 !important;
  color: #000;
  border-bottom: 5px solid #fff;
}

.guten-hfNvY5 {
  background-image: url("../images/game-bg.jpg");
  background-position: center center;
  background-size: cover;
}

.guten-background-overlay {
  background-color: rgba(12, 12, 12, 1);
  opacity: 0.9;
}

.guten-container {
  align-content: center;
  align-items: center;
}

.guten-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  max-width: 1006px;
}

.guten-column-wrapper {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-style: solid;
  border-width: 3px;
  border-color: #93d500;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  align-content: center;
  align-items: center;
  justify-content: center;
}

/* .guten-column-wrapper:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
} */
.header_text {
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins";
  font-size: 48px;
}

.text_center {
  text-align: center;
}

.icons_block {
  justify-content: center;
  gap: 20px;
  margin-top: 60px;
  margin-bottom: 40px;
  color: #93d500;
}

/* .fa-twitter:before {
  content: "\f099";
} */
.media_icons a {
  background-color: rgba(30, 112, 234, 0);
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(147, 213, 0, 1);
  border-radius: 100%;
}

.d_align_block {
  align-items: center;
}

.form_width {
  margin: 0% 25%;
}

.form_width label {
  color: rgba(255, 255, 255, 1);
  display: block;
  font-weight: 700;
  font-size: 16px;
  float: none;
  line-height: 1.3;
  margin: 0 0 4px 0;
  padding: 0;
  word-break: break-word;
  word-wrap: break-word;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: green;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.bg-dark_brown {
  background-color: #1d1742 !important;
}

.payment_img {
  /* border: 2px solid #93d500; */
  border-radius: 5%;
  width: 100px;
}

.heading_md {
  font-size: 48px;
  line-height: 1.2;
}

.acordian_custom .accordion-item button {
  background-color: #08081e !important;
  color: white !important;
  font-size: 20px;
}

.acordian_custom .accordion-item {
  background-color: #08081e !important;
  color: white !important;
  font-size: 20px;
}

.acordian_custom p {
  font-size: 20px;
}

.acordian_custom .accordion-button::after {
  color: white !important;
}

.prom_images {
  width: 80%;
  border-radius: 5%;
}

.dana_bg {
  background-color: white;
  height: 51px;
  width: 119px;
}

.cc_payment_img {
  background-color: white;
  height: 52px;
  width: 107px;
}

.pr_lg_img {
  max-width: 100%;
  border-radius: 5%;
}

.custom_form_btn {
  height: 45px;
}

.btn-danger {
  color: white !important;
  font-weight: 700;
  background: linear-gradient(to bottom, red 0%, red 100%) !important;
}

.custom_spinner {
  position: fixed;
  top: 0;
  left: 220px;
  height: 100vh;
  width: calc(100vw - 245px) !important;
  background: #00000073;
  z-index: 25;
}

.custom_spinner .spinner-border {
  position: absolute;
  top: 50%;
  color: white;
}

@media (max-width: 767.99px) {
  .custom_spinner {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 100vh;
    width: calc(100vw - 0px) !important;
    background: #000000bf;
    z-index: 25;
  }

  .main-logo {
    width: 120px !important;
  }

  .content-bg-image {
    padding-left: 0px !important;
  }
}

.form-floating>.form-select {
  padding-top: 0.625rem !important;
  padding-bottom: .625rem;
}

.height_35{
  height:35px !important
}

.copy-icon-position{
  position: relative;
  margin-top: -72px;
  left:115%;
}

.eye-icon-position{
  position: absolute;
  margin-top: -39px;
  left: 86%;
}